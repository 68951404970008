import React, { useEffect, useState, useRef } from 'react';
import { OrangeButton } from 'components';
import SectionBg from 'assets/images/Wellbeing_banner.png';
import { isBlockVisibleOnScreen } from "helpers/utils";
import { HashLink } from 'react-router-hash-link';

export const HomeSection6 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0.4, 1]);
  }, []);

  return (
    <div ref={sectionRef} className='flex relative pt-[43.3%] overflow-hidden md:pt-[167%]'>
      <img className='absolute top-0 left-0 w-full h-full object-cover object-[75%] md:brightness-50' src={SectionBg} alt="vr background" loading="lazy" />
      <div className='max-w-[45rem] absolute top-1/2 -translate-y-1/2 left-28 z-20 xl:max-w-[27rem] xl:left-16 md:!right-8 md:!left-8 md:!top-[unset] md:bottom-10 md:translate-y-[unset] md:text-left md:items-start'>
        <p className={`text-5xl font-semibold text-white duration-300 xl:text-3xl ${active ? ' translate-x-0 opacity-100' : ' translate-x-1/4 opacity-0'}`}>Immerse in Wellbeing</p>
        <p className={`text-2xl font-semibold text-white my-14 duration-300 xl:text-lg xl:my-8 md:text-base md:!mt-6 md:!mb-4  ${active ? 'delay-100 translate-x-0  opacity-100' : ' translate-x-1/4 opacity-0'}`}>VR revolutionizes employee wellbeing by offering immersive, personalized experiences for stress relief, mindfulness, and resilience training. </p>
        <HashLink to="/our-solutions#wellbeing">
          <OrangeButton className={active ? 'duration-300 delay-300 translate-x-0 opacity-100 hover:delay-0' : 'duration-300  translate-x-1/4 opacity-0'}>Learn more</OrangeButton>
        </HashLink>
      </div>
    </div>
  );
};
