import React, { useEffect, useState, useRef } from 'react';
import { OrangeButton } from 'components';
import SectionBg from 'assets/images/safe-learning.png';
import { isBlockVisibleOnScreen } from "helpers/utils";
import { HashLink } from 'react-router-hash-link';

export const HomeSection5 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0.5, 1]);
  }, []);

  return (
    <div ref={sectionRef} className='flex relative pt-[51.5%] overflow-hidden md:pt-[172%]'>
      <span className='absolute top-0 right-0 w-1/2 h-full  bg-blue-gradient-to-left z-10 pointer-events-none	md:rotate-180 md:right-[unset] md:w-full'></span>
      <img className='absolute top-0 left-0 w-full h-full md:object-cover md:object-[20%]' src={SectionBg} alt="vr background" loading="lazy" />
      <div className='max-w-[38rem] absolute top-1/2 -translate-y-1/2 right-28 text-right z-20 flex flex-col items-end xl:max-w-[27rem] xl:right-16 md:!right-8 md:!left-8 md:!top-[unset] md:bottom-10 md:translate-y-[unset] md:text-left md:items-start'>
        <p className={`text-5xl font-semibold text-white duration-300 xl:text-3xl ${active ? ' translate-x-0 opacity-100' : ' -translate-x-1/4 opacity-0'}`}>Create a safe learning space for everyone</p>
        <p className={`text-2xl font-semibold text-white my-14  max-w-lg duration-300 xl:text-lg xl:my-8 md:text-base md:!mt-6 md:!mb-4 ${active ? ' delay-100 translate-x-0  opacity-100' : ' -translate-x-1/4 opacity-0'}`}>Our company provides immersive VR trainings that cater to everyone, regardless of background or role.</p>
        <HashLink to="/our-solutions#diversity-equity-inclusion">
          <OrangeButton rangeButton className={active ? 'duration-300 delay-300 translate-x-0 opacity-100 hover:delay-0' : 'duration-300  -translate-x-1/4 opacity-0'}>Explore our DEI offer</OrangeButton>
        </HashLink>
      </div>
    </div>
  );
};
