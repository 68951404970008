import React, { useEffect, useState, useRef } from 'react';
import SectionBg from 'assets/images/Junus.png';
import { isBlockVisibleOnScreen } from "helpers/utils";
import { useIsMobile } from "hooks";

export const SolutionsSection8 = () => {
  const sectionRef = useRef();
  const mobile = useIsMobile();
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (mobile) {
      setActive(true);
    } else {
      isBlockVisibleOnScreen(sectionRef.current, setActive, [0, 1]);
    }
  }, [mobile]);

  return (
    <section ref={sectionRef} className='flex flex-col relative mt-[11rem] overflow-hidden sm:!mt-32'>
      <div className='flex flex-col items-center sm:px-10'>
        <p className={`text-6xl font-bold text-white xl:text-4xl lg:text-2xl sm:!text-3xl sm:text-center ${active ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>SEE WHAT THE FUTURE BRINGS!</p>
        <p className={`text-base text-white mt-8 lg:text-sm lg:px-12 lg:text-center lg:mt-5 sm:!text-base sm:!px-0 sm:!mt-8 ${active ? 'duration-300 delay-100 translate-x-0  opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Let our experts in Leadership, DEI, and Wellbeing be your compass through the immersive world of VR training.</p>
      </div>
      <div className='flex pl-[13rem] py-[13rem] mt-32 relative lg:mt-20 lg:pl-[72px] lg:py-20 sm:!px-10 sm:!pt-0 sm:!pb-[210%]'>
        <span className='absolute bg-[#D9D9D9] top-0 -left-[15vw] w-[130vw] h-[130vw] rounded-full pointer-events-none md:-top-10 sm:!top-[unset] sm:-bottom-48 sm:w-[200vw] sm:h-[200vw] sm:!left-[unset] sm:-right-20'></span>
        <div className='flex max-w-[30rem] h-[36rem] z-10 w-full sm:mt-8 sm:min-w-full'>
          <iframe width='100%' height='100%' src="https://share-eu1.hsforms.com/1CxxCWHuSQE2wBnEnZUtTOg2elq82"></iframe>
        </div>
        <img className='absolute w-[58%] -bottom-[10%] right-0 sm:-bottom-20 sm:top-[unset] sm:w-[160%] sm:-right-1/4 sm:max-w-[unset] sm:h-[inherit]' src={SectionBg} alt="vr background" loading="lazy" />
      </div>
    </section>
  );
};
