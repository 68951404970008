import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import CircleArc from 'assets/images/circle-arc.png';
import { useIsMobile } from "hooks";

export const SolutionsSection2 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    if (mobile) {
      setActive(true);
    } else {
      isBlockVisibleOnScreen(sectionRef.current, setActive, [0.3, 1]);
    }
  }, [mobile]);

  return (
    <section ref={sectionRef} id='leadership-development' className='flex items-center relative pt-40'>
      <div className='absolute left-0 bg-orange rounded-e-full w-[45vw] h-[90vw] -top-[37%] sm:hidden' />
      <div className='flex relative justify-between items-center w-full mx-20 xl:ml-10 lg:w-full lg:mr-10 md:mr-10 md:!ml-10 sm:flex-col-reverse'>
        <div className='flex flex-col max-w-[32rem] lg:max-w-[24rem] sm:mt-16 sm:text-center '>
          <p className={`text-6xl font-bold text-white xl:text-4xl ${active ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>LEADERSHIP DEVELOPMENT TRAINING</p>
          <p className={`text-base font-medium text-white text-left mt-10 xl:text-lg lg:!text-base max-w-[95%] xl:mt-8 md:text-base md:!mt-6 sm:text-center  ${active ? 'duration-300 delay-100 translate-x-0  opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>VR training provides immersive, realistic simulations that allow leaders to practice decision-making, communication, and team management in a dynamic and risk-free virtual environment.</p>
        </div>
        <div className='relative w-[30vw] h-[30vw] flex flex-col items-center justify-center sm:w-[calc(100vw-80px)] sm:h-[calc(100vw-80px)]'>
          <img className={cn('absolute top-0 left-0 w-full duration-200 -rotate-180',
            { 'rotate-0 delay-200': active }
          )} src={CircleArc} alt='circle arc' />
          <p className='text-white text-3xl z-20 text-center lg:text-xl'>75% less <br /> training time</p>
        </div>
      </div>
    </section>
  );
};
